<template>
	<div v-loading="loading">
		<div class="item-block">
			<div class="payment-detail">
				<div class="payment-media">
					<el-row>
						<el-col :span="4">
							<div class="media-left"><i class="el-icon-circle-check ns-text-color"></i></div>
						</el-col>

						<el-col :span="16">
							<div class="media-body">
								<el-row>
									<el-col :span="12">
										<div class="payment-text">Your order has been submitted successfully and is waiting
											to be processed!</div>
										<div>
											<span>Amounts payable:</span>
											<span class="payment-money ns-text-color">SGD{{ payInfo.pay_money }}</span>
										</div>
									</el-col>
									<el-col :span="12"></el-col>
								</el-row>
							</div>
						</el-col>

						<!-- <el-col :span="4">
							<div class="media-right">
								<div class="el-button--text" @click="orderOpen ? (orderOpen = false) : (orderOpen = true)">
									订单信息
									<i :class="orderOpen ? 'rotate' : ''" class="el-icon-arrow-down"></i>
								</div>
							</div>
						</el-col> -->
					</el-row>
				</div>

				<div class="order-info">
					<el-row>
						<el-col :span="4" class="order-info-left"></el-col>
						<el-col :span="20">
							<div class="line"></div>
							<div class="order-item">
								<div class="item-label">Order number：</div>
								<div class="item-value">{{ payInfo.pay_money }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">Transaction order number：</div>
								<div class="item-value">{{ payInfo.out_trade_no }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">Order content：</div>
								<div class="item-value">{{ payInfo.pay_detail }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">order amount：</div>
								<div class="item-value">SGD{{ payInfo.pay_money }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">Creation time：</div>
								<div class="item-value">{{ $timeStampTurnTime(payInfo.create_time) }}</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>

		<div class="item-block">
			<div class="block-text" style="font-weight: 700;font-size: 18px;">payment method</div>
			<div class="pay-type-list" v-if="payTypeList.length">
				<div class="pay-type-item" v-for="(item, index) in payTypeList" :key="index"
					:class="payIndex == index ? 'active' : ''" @click="payIndex = index">
					<el-image style="width: 100px; height: 60px;" :src="require('@/assets/images/payment/' + item.icon)"
						fit="contain"></el-image>
					<div style="text-align: center;">{{ item.name }}</div>
				</div>
				<div class="clear"></div>
			</div>
			<div class="no-pay-type" v-else>
				<p>The merchant has not configured a payment method</p>
			</div>
		</div>

		<div class="item-block" v-if="payTypeList.length">
			<div class="order-submit">
				<el-button type="primary" class="el-button--primary" @click="pay">Payment</el-button>
			</div>
			<div class="clear"></div>
		</div>

		<!-- <el-dialog title="请确认支付是否完成" :visible.sync="dialogVisible" width="23%" top="30vh" class="confirm-pay-wrap">
			<div class="info-wrap">
				<i class="el-message-box__status el-icon-warning"></i>
				<span>完成支付前请根据您的情况点击下面的按钮</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="goIndex" size="small">返回首页</el-button>
				<el-button type="primary" @click="goOrderList" size="small">已完成支付</el-button>
			</span>
		</el-dialog> -->

		<!-- 微信支付弹框 -->
		<!-- <el-dialog title="请用微信扫码支付" :visible.sync="openQrcode" width="300px" center>
			<div class="wechatpay-box"><img :src="payQrcode" /></div>
		</el-dialog> -->
	</div>
</template>

<script>
import {
	getPayInfo,
	getPayType,
	checkPayStatus,
	pay
} from '@/api/pay';

export default {
	name: 'pay',
	components: {},
	data: () => {
		return {
			orderOpen: false,
			outTradeNo: '',
			payInfo: {
				pay_money: 0
			},
			payIndex: 0,
			payTypeList: [],
			payUrl: '',
			timer: null,
			payQrcode: '',
			openQrcode: false,
			loading: true,
			test: null,
			dialogVisible: false
		};
	},
	created() {
		if (!this.$route.query.code) {
			this.$router.push({
				path: '/'
			});
			return;
		}
		this.outTradeNo = this.$route.query.code;
		this.getPayInfo();
		this.getPayType();
		// this.checkPayStatus();
	},
	methods: {
		getPayInfo() {
			getPayInfo({
				out_trade_no: this.outTradeNo,
				forceLogin: true
			})
				.then(res => {
					const {
						code,
						message,
						data
					} = res;
					if (data) {
						this.payInfo = res.data;
					}
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({
								path: '/member/order_list'
							});
						}
					});
				});
		},
		getPayType() {
			getPayType({})
				.then(res => {
					const {
						code,
						message,
						data
					} = res;
					if (code >= 0) {
						if (res.data.pay_type == '') {
							this.payTypeList = [];
						} else {
							this.payTypeList = [];
							let arr = res.data.pay_type.split(',');
							let list = [{
								name: 'BankCard 1',
								icon: 'CreditCard2.png',
								type: 'kbank'
							},
							{
								name: 'PayPal',
								icon: 'paypalPAY.png',
								type: 'paypal'
							},
							{
								name: 'CreditCard',
								icon: 'CreditCard.png',
								type: 'credit',
							},
							{
								name: 'BankCard 2',
								icon: 'BankCard_2.png',
								type: 'wontonspay',
							}
							]
							for (let i = 0; i < arr.length; i++) {
								list.map(v => {
									if (v.type == arr[i]) {
										this.payTypeList.push(v);
									}
								})
							}
						}
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		checkPayStatus() {
			this.timer = setInterval(() => {
				checkPayStatus({
					out_trade_no: this.outTradeNo
				})
					.then(res => {
						const {
							code,
							message,
							data
						} = res;
						if (code >= 0) {
							if (code == 0) {
								if (data.pay_status == 2) {
									clearInterval(this.timer);
									this.dialogVisible = false;
									this.$router.push({
										path: '/result?code=' + this.payInfo.out_trade_no
									});
								}
							} else {
								clearInterval(this.timer);
							}
						}
					})
					.catch(err => {
						clearInterval(this.timer);
						// this.$router.push({
						// 	path: '/'
						// });
					});
			}, 1000);
		},
		pay() {
			var payType = this.payTypeList[this.payIndex];
			if (!payType) return;

			let url = '/api/paypal/pay';
			if (payType.type == 'kbank' || payType.type == 'wontonspay') {
				url = '/api/wontonspay/create'
			}
			var loading = this.$loading({
				lock: true,
				text: "loading...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			pay({
				out_trade_no: this.payInfo.out_trade_no,
				pay_type: payType.type,
				app_type: 'pc',
				url: url,
				return_url: encodeURIComponent('/result')
			})
				.then(res => {
					const {
						code,
						message,
						data
					} = res;
					loading.close();
					if (code >= 0) {
						// return

						window.location.href = data.url
					} else {
						this.$message({
							message: message,
							type: 'warning'
						});
					}
				})
				.catch(err => {
					loading.close();
					this.$message.error(err.message);
				});
		},
		open() {
			this.dialogVisible = true;
		},
		goIndex() {
			clearInterval(this.timer);
			this.dialogVisible = false;
			this.$router.push({
				path: '/'
			});
		},
		goOrderList() {
			clearInterval(this.timer);
			this.dialogVisible = false;
			this.$router.push({
				path: '/member/order_list'
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.clear {
	clear: both;
}

.item-block {
	padding: 0 15px 1px;
	margin: 10px 0;
	border-radius: 0;
	border: none;
	background: #ffffff;

	.block-text {
		border-color: #eeeeee;
		color: $ns-text-color-black;
		padding: 7px 0;
		border-bottom: 1px;
	}
}

.media-left {
	text-align: center;

	i {
		font-size: 65px;
	}
}

.payment-detail {
	padding: 30px 0;
	transition: 2s;
}

.media-right {
	text-align: center;
	line-height: 65px;
	cursor: pointer;

	i.rotate {
		transform: rotate(180deg);
		transition: 0.3s;
	}
}

.payment-text {
	font-size: 20px;
}

.payment-time {
	font-size: 12px;
	line-height: 65px;
	color: #999;
}

//支付方式
.order-submit {
	float: right;
	padding: 10px;
}

.pay-type-list {
	padding: 20px 0;
}

.pay-type-item {
	display: inline-block;
	border: 2px solid #eeeeee;
	padding: 5px 20px;
	margin-right: 20px;
	cursor: pointer;
}

.pay-type-item.active {
	border-color: $base-color;
}

.mobile-wrap {
	width: 300px;
}

.order-info {
	.order-item {
		padding: 1px 0;

		.item-label {
			display: inline-block;
			// width: 180px;
			text-align: right;
			// border: 1px solid red;
		}

		.item-value {
			display: inline-block;
		}
	}

	.line {
		width: 100%;
		height: 1px;
		background: #f2f2f2;
		margin: 20px 0 10px 0;
	}

	.order-info-left {
		height: 1px;
	}
}

.wechatpay-box {
	text-align: center;

	img {
		width: 80%;
	}
}

.confirm-pay-wrap {
	.el-dialog__body {
		padding: 10px 15px;
	}

	.info-wrap {
		i {
			position: initial;
			vertical-align: middle;
			transform: initial;
		}

		span {
			vertical-align: middle;
			padding: 0 10px;
		}
	}
}
</style>
<style lang="scss">
.confirm-pay-wrap {
	.el-dialog__body {
		padding: 10px 15px;
	}

	.el-dialog__footer {
		padding-top: 0;
		padding-bottom: 10px;
	}
}
</style>
